import { CommonModule } from '@angular/common'
import { Component, Input, inject } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AlertService } from '@core/services/alert.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { ReleaseNoteApiService } from '@modules/update/services/release-note-api.service'
import { ReleaseNoteFormService } from '@modules/update/states/release-note-form.service'
import { TuiDialogService } from '@taiga-ui/core'

@Component({
    selector: 'app-release-note-card',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ...TaigaUiImports],
    templateUrl: './release-note-card.component.html',
    styleUrls: ['./release-note-card.component.scss'],
})
export class ReleaseNoteCardComponent {
    releaseNoteFormService = inject(ReleaseNoteFormService)
    ReleaseNoteApiService = inject(ReleaseNoteApiService)
    private alertService = inject(AlertService)
    private dialog = inject(TuiDialogService)

    @Input() releaseNote: ReleaseNote | null = null

    edit() {}
}
