<div class="card h-full overflow-hidden bg-base-100 shadow-xl">
    <div class="card-body p-4">
        <div (click)="edit()" class="text-xl hover:cursor-pointer hover:text-info">
            {{ releaseNote.title }}
        </div>
        <div class="flex items-center justify-between">
            <div class="text-lg text-warning">
                {{ releaseNote.version }}
            </div>
            <div class="text-md text-slate-500">
                <span class="badge hover:badge-outline">
                    {{ releaseNote.releaseDate | date }}
                </span>
            </div>
        </div>

        <div [innerHtml]="releaseNote.content" class="mt-2 h-auto text-slate-500"></div>
    </div>
</div>
